export const i18_TRANSLATION = 'translation1-5-12'
export const i18_EDITTERMIN = 'edittermin1-1-1'
export const i18_LIST = 'list-1-4-8'
export const i18_LISTDOC = 'listdoc1-1-1'
export const i18_MESSAGE = 'message1-1-1'
export const i18_ARTIST = 'artist1-5-3'
export const i18_TEMPLATES = 'templates1-5-5'
export const i18_PDFSIGN = 'pdfsign1-5-5'
export const i18_NACHRICHT = 'nachricht1-1-9'
export const i18_STUDIO = 'studio1-4-1'
export const i18_KUNDE = 'kunde1-1-8'
export const i18_EDITPROJEKT = "editprojekt1-1-6"
export const i18_ICAL_IMPORT = "icalimport"
export const i18_EINNAHMEN = 'einnahmen1-1-8'
export const i18_TIMELINE = 'timeline'
export const i18_CUSTOMER_PORTAL = 'customer-portal1-4-8'
