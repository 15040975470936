import {
    ADD_SEARCHED_CALENDAR_KUNDEN,
    CHANGE_ARTIST_AND_ZEITRAUM,
    CHANGE_CALENDAR_EVENT_FILTER,
    CHANGE_CALENDAR_SELECTED_LOCATION,
    CHANGE_CALENDAR_SELECTED_RUBRIK,
    CHANGE_CALENDAR_SELECTED_TERMIN_AND_KUND_ID,
    CHANGE_CALENDAR_SELECTED_TERMIN_ID,
    CHANGE_CALENDER_SELECTED_KUND_ID,
    CHANGE_VIEW_TYPE,
    CLEAR_CALENDAR_SEARCH_KUNDE,
    CLEAR_CALENDAR_SELECTED_ARTIST_LOCATION,
    CLEAR_CALENDAR_SELECTED_TERMIN_ID,
    CLEAR_CALENDAR_TIMESLOTS,
    DECREASE_CALENDAR_SLOT_DURATION,
    DELETE_SELECTED_TERMIN_SUCCESS,
    INCREASE_CALENDAR_SLOT_DURATION,
    LOAD_CALENDAR,
    LOAD_CALENDAR_FAILURE,
    LOAD_CALENDAR_KUNDEN_SUCCESS,
    LOAD_CALENDAR_NOCHANGE,
    LOAD_CALENDAR_SELECTED_KUNDEN_TERMINE,
    LOAD_CALENDAR_SELECTED_KUNDEN_TERMINE_FAILURE,
    LOAD_CALENDAR_SELECTED_KUNDEN_TERMINE_SUCCESS,
    LOAD_CALENDAR_SUCCESS,
    LOAD_CALENDAR_TERMIN_TOASTER_DATA,
    LOAD_CALENDAR_TERMIN_TOASTER_DATA_FAILURE,
    LOAD_CALENDAR_TERMIN_TOASTER_DATA_SUCCESS,
    LOAD_CALENDAR_TERMIN_TOASTER_IMAGES,
    LOAD_CALENDAR_TERMIN_TOASTER_IMAGES_FAILURE,
    LOAD_CALENDAR_TERMIN_TOASTER_IMAGES_SUCCESS,
    SEARCH_CALENDAR_KUNDEN,
    SEARCH_CALENDAR_KUNDEN_SUCCESS,
    SEARCH_CALENDAR_TERMINSLOTS,
    SEARCH_CALENDAR_TERMINSLOTS_FAILURE,
    SEARCH_CALENDAR_TERMINSLOTS_SUCCESS,
    SELECT_CALENDAR_ARTIST,
    SELECT_CALENDAR_ARTIST_LOCATION,
    SELECT_SEARCHED_KUNDID,
    SHOW_CALENDAR_SINGLE_ARTIST_VIEW_FOR_ZEITRAUM,
    SWITCH_CALENDAR_TO_ARTIST_VIEW,
} from "./calendarActions";
import {
    asyncLoadingActionFailure,
    asyncLoadingActionSuccess,
    asyncLoadingNoDataChange,
    clearAsyncLoading,
    initiateAsyncLoadingAction,
    initiateAsyncLoadingWithParamsAction,
    replacePayloadFields,
} from "../utils/reducerUtils";
import { LOAD_LOGGED_ARTIST_SETTINGS_SUCCESS } from "../common/commonActions";
import { LOGIN_STUDIO, LOGOUT_ARTIST } from "../auth/authActions";
import { DELETE_KUNDE, SAVE_KUNDE } from "../kunde/kundeAction";
import { MERGE_DOUBLETS } from "../list/listActions";
import { omit } from "../utils/lodash-replacement";
import { unset } from "lodash";

export const SINGLE_ARTIST_VIEW = "singleArtist";
export const MULTIPLE_ARTIST_VIEW = "multipleArtists";

const INITIAL = {
    viewType: SINGLE_ARTIST_VIEW,
    slotDuration: 15,
};

const SLOT_DURATIONS = [5, 10, 15, 30, 60];

const increaseSlotDuration = (actual) =>
    SLOT_DURATIONS[Math.min(SLOT_DURATIONS.indexOf(actual) + 1, SLOT_DURATIONS.length - 1)];
const decreaseSlotDuration = (actual) => SLOT_DURATIONS[Math.max(SLOT_DURATIONS.indexOf(actual) - 1, 0)];

export default function calendarReducer(state = INITIAL, action) {
    switch (action.type) {
        case LOGOUT_ARTIST:
        case LOGIN_STUDIO:
            return { ...INITIAL };
        case LOAD_LOGGED_ARTIST_SETTINGS_SUCCESS:
            return {
                ...state,
                resourceFilter: action.payload.artistSettings?.resourceFilter,
                eventFilter: action.payload.artistSettings?.eventFilter,
            };

        case LOAD_CALENDAR:
            return initiateAsyncLoadingWithParamsAction(state, "events", { zeitraum: action.payload.zeitraum });
        case LOAD_CALENDAR_SUCCESS:
            return asyncLoadingActionSuccess(state, action, "events");
        case LOAD_CALENDAR_NOCHANGE:
            return asyncLoadingNoDataChange(state, "events");
        case LOAD_CALENDAR_FAILURE:
            return asyncLoadingActionFailure(state, "events");

        case LOAD_CALENDAR_SELECTED_KUNDEN_TERMINE:
            return initiateAsyncLoadingAction(state, "selectedKundenTermine");
        case LOAD_CALENDAR_SELECTED_KUNDEN_TERMINE_SUCCESS:
            return asyncLoadingActionSuccess(state, action, "selectedKundenTermine");
        case LOAD_CALENDAR_SELECTED_KUNDEN_TERMINE_FAILURE:
            return asyncLoadingActionFailure(state, "selectedKundenTermine");

        case LOAD_CALENDAR_TERMIN_TOASTER_DATA:
            return initiateAsyncLoadingAction(state, "selectedTerminToaster");
        case LOAD_CALENDAR_TERMIN_TOASTER_DATA_SUCCESS:
            return asyncLoadingActionSuccess(state, action, "selectedTerminToaster");
        case LOAD_CALENDAR_TERMIN_TOASTER_DATA_FAILURE:
            return asyncLoadingActionFailure(state, "selectedTerminToaster");
        case LOAD_CALENDAR_TERMIN_TOASTER_IMAGES:
            return initiateAsyncLoadingAction(state, "toasterImages");
        case LOAD_CALENDAR_TERMIN_TOASTER_IMAGES_SUCCESS:
            return asyncLoadingActionSuccess(state, action, "toasterImages");
        case LOAD_CALENDAR_TERMIN_TOASTER_IMAGES_FAILURE:
            return asyncLoadingActionFailure(state, "toasterImages");
        case CLEAR_CALENDAR_SELECTED_TERMIN_ID:
            return {
                ...clearAsyncLoading(state, "selectedTerminToaster"),
                toasterImages: undefined,
                selectedTerminID: undefined,
                selectedTerminProjekt: undefined,
            };

        case CHANGE_CALENDAR_SELECTED_LOCATION:
            return {
                ...state,
                resourceFilter: {
                    ...state.resourceFilter,
                    tatortId: action.payload.tatortId,
                },
            };
        case CHANGE_CALENDAR_SELECTED_RUBRIK:
            return {
                ...state,
                resourceFilter: {
                    ...state.resourceFilter,
                    rubrikId: action.payload.rubrikId,
                },
            };

        case INCREASE_CALENDAR_SLOT_DURATION:
            return {
                ...state,
                slotDuration: increaseSlotDuration(state.slotDuration),
            };
        case DECREASE_CALENDAR_SLOT_DURATION:
            return {
                ...state,
                slotDuration: decreaseSlotDuration(state.slotDuration),
            };

        case CHANGE_CALENDAR_SELECTED_TERMIN_ID: {
            return {
                ...state,
                selectedTerminID: action.payload.selectedTerminID,
                selectedTerminProjekt: undefined,
            };
        }
        case CHANGE_CALENDAR_SELECTED_TERMIN_AND_KUND_ID: {
            return {
                ...state,
                selectedTerminID: action.payload.terminAndKundId.terminId,
                selectedKundID: action.payload.terminAndKundId.kundId,
                selectedTerminProjekt: undefined,
            };
        }

        case SHOW_CALENDAR_SINGLE_ARTIST_VIEW_FOR_ZEITRAUM:
            return {
                ...initiateAsyncLoadingWithParamsAction(state, "events", { zeitraum: action.payload.zeitraum }),
                viewType: SINGLE_ARTIST_VIEW,
            };

        case DELETE_SELECTED_TERMIN_SUCCESS: {
            if (state.events.termine) {
                // eslint-disable-next-line no-unused-vars
                const { [state.selectedTerminID]: removed, ...restEvents } = state.events.termine;
                return {
                    ...state,
                    selectedTerminID: undefined,
                };
            }
            return state;
        }

        case SEARCH_CALENDAR_KUNDEN:
            return {
                ...initiateAsyncLoadingWithParamsAction(state, "searchedKunden", { namePart: action.payload.namePart }),
                selectedTerminID: action.payload.selectedTerminID,
                selectedKundID: undefined,
                selectedTerminProjekt: undefined,
            };
        case SEARCH_CALENDAR_KUNDEN_SUCCESS:
            return asyncLoadingActionSuccess(state, action, "searchedKunden");
        case MERGE_DOUBLETS:
            return {
                ...state,
                selectedKundID:
                    state.selectedKundID !== +action.payload?.mergeData?.kundIdFrom ? state.selectedKundID : undefined,
                searchedKunden:
                    state.searchedKunden &&
                    state.searchedKunden.filter((k) => k.KundID !== +action.payload?.mergeData?.kundIdFrom),
                kunden: state.kunden && unset(state.kunden, +action.payload?.mergeData?.kundIdFrom),
            };
        case ADD_SEARCHED_CALENDAR_KUNDEN:
            return {
                ...state,
                searchedKunden: state.searchedKunden
                    ? state.searchedKunden
                          .filter(
                              (k) => action.payload.searchedKunden.find((sk) => sk.KundID === k.KundID) === undefined
                          )
                          .concat(action.payload.searchedKunden)
                    : action.payload.searchedKunden,
            };
        case CLEAR_CALENDAR_SEARCH_KUNDE:
            return clearAsyncLoading(state, "searchedKunden");

        case SELECT_SEARCHED_KUNDID:
            return {
                ...state,
                selectedSearchedKunde: state.searchedKunden.find(
                    (k) => k.KundID === action.payload.selectedSearchKundID
                ),
            };

        case SAVE_KUNDE:
            return {
                ...state,
                kunden: action.payload?.saveKundeData?.kunde
                    ? {
                          ...state.kunden,
                          [action.payload?.saveKundeData?.kunde.KundID]: action.payload?.saveKundeData?.kunde,
                      }
                    : state.kunden,
                selectedKundID: action.payload?.saveKundeData?.kunde
                    ? action.payload?.saveKundeData?.kunde.KundID
                    : state.selectedKundID,
            };
        case DELETE_KUNDE:
            return {
                ...state,
                kunden:
                    state.kunden && state.kunden[action.payload.kundId]
                        ? omit(state.kunden, action.payload.kundId)
                        : state.kunden,
                selectedKundID: undefined,
                selectedSearchedKunde: undefined,
                searchedKunden:
                    state.searchedKunden && state.searchedKunden.filter((k) => k.KundID !== action.payload.kundId),
            };

        case CLEAR_CALENDAR_SELECTED_ARTIST_LOCATION:
            return {
                ...state,
                selectedLocationKeys: undefined,
            };

        case SEARCH_CALENDAR_TERMINSLOTS:
            return {
                ...state,
                timeslots: undefined,
                searchTimeslotsLoading: true,
            };
        case SEARCH_CALENDAR_TERMINSLOTS_SUCCESS:
            return {
                ...state,
                searchTimeslotsLoading: false,
                ...action.payload,
            };
        case SEARCH_CALENDAR_TERMINSLOTS_FAILURE:
            return {
                ...state,
                searchTimeslotsLoading: false,
            };
        case CLEAR_CALENDAR_TIMESLOTS:
            return {
                ...state,
                searchTimeslotsLoading: undefined,
                timeslots: undefined,
            };
        case SELECT_CALENDAR_ARTIST:
            return {
                ...replacePayloadFields(state, action),
                eventsNewParams: { ...state.eventsParams },
            };
        case SWITCH_CALENDAR_TO_ARTIST_VIEW:
            return {
                ...state,
                viewType: SINGLE_ARTIST_VIEW,
                calendarArtist: action.payload.calendarArtist,
            };
        case CHANGE_ARTIST_AND_ZEITRAUM:
            return {
                ...state,
                eventsNewParams: { zeitraum: action.payload.params.zeitraum },
                calendarArtist: action.payload.params.calendarArtist,
                viewType: SINGLE_ARTIST_VIEW,
            };
        case CHANGE_VIEW_TYPE:
        case CHANGE_CALENDAR_EVENT_FILTER:
        case LOAD_CALENDAR_KUNDEN_SUCCESS:
        case CHANGE_CALENDER_SELECTED_KUND_ID:
        case SELECT_CALENDAR_ARTIST_LOCATION:
            return replacePayloadFields(state, action);

        default:
            return state;
    }
}
